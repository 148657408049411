import { useContext, useMemo } from "react"
import { CanvasEditor } from "@book-editor-v2/products/canvas/store/canvas-editor"
import { MiniBookEditor } from "@book-editor-v2/products/mini-book/store/mini-book-editor"
import { BookEditor } from "@book-editor-v2/store/book-editor"

import { IPage } from "../@types"
import { BookEditorContext } from "../contexts"

/**
 * Получить хешированный массив со страницами в которых используется фото
 *
 * @param photoId идентификатор фотографии
 * @returns массив со списком страниц
 */
export const useUsedPages = (photoId: string) => {
  const bookEditor = useContext(BookEditorContext)

  return useMemo(() => {
    if (bookEditor instanceof BookEditor || bookEditor instanceof MiniBookEditor) {
      const pages = bookEditor.productConfiguration.pages as IPage[]

      return pages
        .map((page, index) => ({ ...page, index }))
        .filter((page) => page.photos.findIndex((pagePhoto) => pagePhoto.id === photoId) > -1)
    }

    if (bookEditor instanceof CanvasEditor) {
      const canvasConfiguration = (bookEditor as CanvasEditor).productConfiguration

      return canvasConfiguration.photo.id === photoId ? [{ index: -1, ...canvasConfiguration.photo }] : []
    }

    return []
  }, [photoId, bookEditor])
}
