import { ICanvasConfiguration, IPhoto } from "@book-editor-v2/@types"
import { makeAutoObservable } from "mobx"

/**
 * Класс реализует интерфейс конфигурации холста.
 * Может быть инициализирован только внутри mobx store холста.
 */
class CanvasProductConfiguration implements ICanvasConfiguration {
  photo: IPhoto

  constructor() {
    this.photo = null;

    makeAutoObservable(this)
  }
}

export { CanvasProductConfiguration }
