import { useI18n } from "next-localization"
import cx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { useGlobalState } from "@app/contexts"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { useCommentsModal } from "@app/features/favorites/store"
import { Button } from "@app/ui/button"
import { useMobileMenu } from "features/gallery/store/hooks"

import styles from "./complete-selection.module.scss"

export const CompleteSelection = ({ countPhotos }) => {
  const i18n = useI18n()
  const { showCommentModal } = useCommentsModal()
  const {
    userAgent: { isMobile },
  } = useGlobalState()
  const { $isMobileMenuVisible } = useMobileMenu()

  const handleCompleteSelecionButtonClick = () => {
    trackYandexMetrikaAction(
      isMobile ? "click-сomplete-photo-selection-mobile" : "click-сomplete-photo-selection-desktop"
    )
    showCommentModal()
  }

  return (
    <div className={cx(styles["root"], { [styles["z-index"]]: $isMobileMenuVisible })}>
      <p className={styles["text"]}>
        {countPhotos > 0 && (
          <>
            {i18n.t("favoritesListPage.description")} <b>{i18n.t("favoritesListPage.countPhoto", { countPhotos })}</b>
          </>
        )}
      </p>
      <Button
        onClick={() => handleCompleteSelecionButtonClick()}
        className={cx(styles["button"], QA_CLASS_NAMES.favourites.finish)}
      >
        {i18n.t("favoritesListPage.completeSelectionButton")}
      </Button>
    </div>
  )
}
