import { ICanvasConfiguration, ICanvasEditor, IClientAlbumId } from "@book-editor-v2/@types"
import { Photo } from "@book-editor-v2/store"
import { makeAutoObservable } from "mobx"

import { CLIENT_ALBUM_ID } from "@app/features/client-upload/constants"

import { CanvasProductConfiguration } from "./canvas-product-configuration"

/**
 * Класс реализует интерфейс редактора холста.
 * Инициализирует mobx store.
 */
export class CanvasEditor implements ICanvasEditor {
  saved: boolean;
  clientAlbum: IClientAlbumId
  productOptionValues: Object
  productConfiguration: ICanvasConfiguration
  isReady: boolean

  constructor() {
    this.isReady = false
    this.productOptionValues = {}
    this.productConfiguration = null
    this.clientAlbum = null;
    this.productConfiguration = new CanvasProductConfiguration()

    makeAutoObservable(this, {}, { autoBind: true })
  }

  /**
   * Установить конфигурацию холста из JSON
   * @param configuration { string } конфигурация холста
   */
  set JSON(configuration: string) {
    const { photo: photoConfiguration } = JSON.parse(configuration).productConfiguration
    const clientAlbumId = JSON.parse(configuration)?.clientAlbum?.id || localStorage.getItem(CLIENT_ALBUM_ID)

    this.productConfiguration.photo = new Photo(
      photoConfiguration.id,
      photoConfiguration.offset,
      photoConfiguration?.type,
      clientAlbumId,
      JSON.parse(configuration)?.clientPhoto
    )
    this.clientAlbum = { id: clientAlbumId }
    this.isReady = true
  }

  /**
   * Получить конфигурацию холста в формате JSON
   * @return { string } конфигурация холста
   */
  get JSON(): string {
    return JSON.stringify({
      clientAlbum: this.clientAlbum,
      productOptionValues: this.productOptionValues,
      productConfiguration: this.productConfiguration,
    })
  }

  get hasUnsavedChanges() {
    return !this.saved
  }

  setUnsavedChanges(value) {
    this.saved = value
  }
}
