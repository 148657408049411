import React from "react"
import { IBookEditor, TSizesType } from "@book-editor-v2/@types"

type TBookEditorContext = IBookEditor
type TDisplayValidationContext = boolean
type TSizesContext = { sizes: TSizesType }
type TSizesMiniBookContext = { sizes: TSizesType }

const BookEditorContext = React.createContext<TBookEditorContext>(null)
const BookEditorProvider = BookEditorContext.Provider

const DisaplayValidationContext = React.createContext<TDisplayValidationContext>(false)
const DisplayValidationProvider = DisaplayValidationContext.Provider

const SizesContext = React.createContext<TSizesContext>(null)
const SizesProvider = SizesContext.Provider

const SizesMiniBookContext = React.createContext<TSizesMiniBookContext>(null)
const SizesMiniBookProvider = SizesMiniBookContext.Provider

export {
  BookEditorContext,
  BookEditorProvider,
  DisaplayValidationContext,
  DisplayValidationProvider,
  SizesContext,
  SizesMiniBookContext,
  SizesMiniBookProvider,
  SizesProvider,
}
